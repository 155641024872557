$("body").on("change.billing-address-customer-toggle", ".billing-address-customer-toggle .billing-address-customer-type", function () {
    var $typeObj = $(this);
    var $container = $typeObj.parents(".billing-address-customer-toggle");
    var type = $typeObj.val();
    var radioItem = $typeObj.parent(".radio-box-item");
    $(".billing-address-form:hidden").show();
    $container.find(".radio-box-item").removeClass("active");
    radioItem.addClass("active");
    $container.find("[data-customer-type]").hide();
    $container.find("[data-customer-type-2]").hide();
    $container.find("[data-customer-type]").each(function (k, v) {
        if ($(v).data("customer-type") == type || $(v).data("customer-type-2") == type) {
            $(v).show();
        }
    });
});

$("body").on("change.checkout-address-input-container", ".checkout-address-input-container .billing-address-customer-type", function () {
    var $typeObj = $(this);
    var $container = $typeObj.parents(".checkout-address-input-container");
    var type = $typeObj.val();
    var radioItem = $typeObj.parent(".radio-box-item");
    $(".billing-address-form:hidden").show();
    $container.find(".radio-box-item").removeClass("active");
    radioItem.addClass("active");
    $container.find("[data-customer-type]").hide();
    $container.find("[data-customer-type-2]").hide();
    $container.find("[data-customer-type]").each(function (k, v) {
        if ($(v).data("customer-type") == type || $(v).data("customer-type-2") == type) {
            $(v).show();
        }
    });
});

$("body").on("change", ".health-found-select", function () {
    var val = $(this).val();
    var localHealthFundData = JSON.parse(healthFundData);
    if (val != 0) {
        healthFund = localHealthFundData[$(this).val()];
        $(".address-input-company-name").html(healthFund.company_name);
        $(".address-input-tax-number").html(healthFund.tax_number);

        var address = "";
        address += "<div>" + healthFund.country + "</div>";
        address += "<div>" + healthFund.state + "</div>";
        address += "<div>" + healthFund.post_code + "</div>";
        address += "<div>" + healthFund.city + "</div>";
        address += "<div>" + healthFund.address_line_1 + "</div>";
        if (healthFund.address_line_2) {
            address += "<div>" + healthFund.address_line_2 + "</div>";
        }

        $(".address-input-address").html(address);
    } else {
        $(".address-input-company-name").html("");
        $(".address-input-tax-number").html("");
        $(".address-input-address").html("");
    }
});

$('body').on('click', '.toLogin', function () {

    $("#checkoutauthloginform-email").val($("#checkoutauthguestform-email").val());
    $('a[href="#login-tabs-tablogin"]').trigger("click");

});